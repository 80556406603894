<template>
  <el-amap vid="amap" 
  :center='center'
  :zoom='zoom'
  id="container">
  </el-amap>
</template>
<script>
export default {
  name: "AboutMap",
  data() {
    return {
      center: [116.33, 40.05],
      zoom: 50,
    };
  },
  methods: {
    point() {
      var map = new AMap.Map("container", {
        resizeEnable: true,
        center: [116.33, 40.05],
        zoom: 17,
      });
      var infoWindow = new AMap.AdvancedInfoWindow({
        placeSearch: true,
        asOrigin: true,
        asDestination: true,
        autoMove: true,
        offset: new AMap.Pixel(1, -33),
        content:
          '<div class="info-container"><div class="info-title">北京市海淀区</div><div class="info-content">安宁庄东路甲16号院兴安商务办公区5号楼166室</div></div>',
      });
      var marker = new AMap.Marker({
        position: [116.33, 40.05],
        // icon: "//vdata.amap.com/icons/b18/1/2.png",
        map: map,
      });
      marker.on("click", markerClick);
      marker.emit("click", { target: marker });
      function markerClick(e) {
        infoWindow.open(map, e.target.getPosition());
      }
      map.setFitView();
    },
  },
  mounted() {
    this.point();
  },
};
</script>
<style lang="scss">
.amap-info-combo,
.amap-info-tabs,
.status-origin {
  width: 248px;
}
.info-container {
  width: 248px;
  position: relative;
  box-shadow: none;
  bottom: 0;
  left: 0;
}
.amap-info-combo .tab {
  padding: 0 0px;
}
.amap-info-combo .input-label {
  padding: 0 3px;
}

.info-title {
  font-size: 12px;
  line-height: 26px;
  background: none repeat scroll 0 0 #f9f9f9;
  border-bottom: 1px solid #ccc;
  padding: 0px 0 0 6px;
  font-weight: lighter;
  letter-spacing: 1px;
}
.amap-adcontent-body {
  border: solid 1px silver;
}
.amap-adcombo-close {
  margin: 2px 2px 0 0;
  background: url(https://webapi.amap.com/images/close2.gif) center center
    no-repeat;
}

.info-content {
  font: 12px Helvetica, "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", Arial;
  padding: 4px;
  color: #666666;
  line-height: 23px;
}

.info-content img {
  float: left;
  margin: 3px;
}

.amap-info-combo .keyword-input {
  height: 25px;
  border-radius: 2px 0 0 2px;
}
#container {
  height: 569px;
  margin-bottom: 100px;
}
.infowindow-content {
  width: 90px;
  padding: 10px 0 0 10px;
}
.amap-logo {
  display: none !important;
}
.amap-copyright {
  opacity: 0;
}
@media screen and(max-width:1200px) {
  #container {
    height: 200px;
  }
}
</style>
