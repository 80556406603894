<template>
  <div>
    <el-row>
    <el-col>
        <div class="bgimg"><img src="http://cdn.valuetech.com.cn/images/%E5%A4%B4%E5%9B%BE/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC.jpg"></div>
    </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="btnBox">
      <el-col :xs="4" :sm="6" :md="8" :lg="3" :xl="3"><el-button class="aboutBtnActive text_ov_el" @click="handeleBtn1Click">{{$t('aboutBtnName')[0]}}</el-button></el-col>
      <el-col :xs="4" :sm="6" :md="8" :lg="3" :xl="3"><el-button class="text_ov_el" @click="handeleBtn2Click">{{$t('aboutBtnName')[1]}}</el-button></el-col>
      <el-col :xs="4" :sm="6" :md="8" :lg="3" :xl="3"><el-button class="text_ov_el" @click="handeleBtn3Click">{{$t('aboutBtnName')[2]}}</el-button></el-col>
      <el-col :xs="4" :sm="6" :md="8" :lg="3" :xl="3"><el-button class="text_ov_el" @click="handeleBtn4Click">{{$t('aboutBtnName')[3]}}</el-button></el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :xs="22" :sm="22" :md="18" :lg="16" :xl="16" >
        <div class="companyInfo">
          <img :src="i" alt="" v-if="index == current && index != 1" v-for="(i,index) in msgTemplate" :key="index">
          <p v-show="current == 1" class="companyProfile" style="text-indent: 30px">{{$t('aboutMsg').companyProfile}}</p>
          <!-- <p v-show="current == 2" class="companyCulture" style="text-indent: 30px">{{$t('aboutMsg').companyCulture}}</p> -->
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" class="companyMsg">
      <el-col :xs="22" :sm="7" :md="8" :lg="7" :xl="7">
        <h1>{{$t('aboutMsg').title}}</h1>
        <br>
        <p>{{$t('aboutMsg').phone}}</p>
        <p>{{$t('aboutMsg').email}}</p>
        <p>{{$t('aboutMsg').address}}</p>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" id="mapBox">
      <el-col :span="22">
          <about-map></about-map>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import AboutMap from "./c-components/AboutMap.vue";

export default {
  data() {
    return {
      current: 0,
      msgTemplate: [
        "http://cdn.valuetech.com.cn/images/About%E9%A1%B5/%E5%8F%91%E5%B1%95%E5%8E%86%E7%A8%8B.png",
        "http://cdn.valuetech.com.cn/images/About%E9%A1%B5/%E4%BC%81%E4%B8%9A%E4%BB%8B%E7%BB%8D.png",
        "http://cdn.valuetech.com.cn/images/About%E9%A1%B5/%E4%BC%81%E4%B8%9A%E6%96%87%E5%8C%96.png",
        "http://cdn.valuetech.com.cn/images/About%E9%A1%B5/%E4%BC%81%E4%B8%9A%E8%B5%84%E8%B4%A8.png",
      ],
    };
  },
  components: {
    AboutMap,
  },
  methods: {
    btnCheck(e) {
      const ev = window.event || e;
      const eDom = ev.path || (ev.composedPath && ev.composedPath());
      const btnItems = document.getElementsByClassName("btnBox")[0].children;
      for (let i = 0; i < btnItems.length; i++) {
        btnItems[i].children[0].classList.remove("aboutBtnActive");
      }
      if (eDom[1].nodeName == "BUTTON") {
        eDom[1].classList.add("aboutBtnActive");
        return;
      }
      eDom[0].classList.add("aboutBtnActive");
    },
    handeleBtn1Click(e) {
      this.current = 0;
      this.btnCheck(e);
    },
    handeleBtn2Click(e) {
      this.current = 1;
      this.btnCheck(e);
    },
    handeleBtn3Click(e) {
      this.current = 2;
      this.btnCheck(e);
    },
    handeleBtn4Click(e) {
      this.current = 3;
      this.btnCheck(e);
    },
  },
};
</script>
<style lang="scss">
@import "assets/scss/global.scss";

.companyProfile,
.companyCulture {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 39px;
}

.btnBox {
  margin: 50px 0;
}
.el-button {
  font-size: 30px;
  font-weight: 400;
  width: 180px;
  padding: 0;
  transform: scale(0.78);
  // height: 56px;
  border-radius: 10px;
  line-height: 56px;
}

.el-button:focus {
  background-color: #0c6ac1;
  color: #fff;
  border: 1px solid #0c6ac1;
}
.aboutBtnActive {
  background-color: #0c6ac1;
  color: #fff;
  border: 1px solid #0c6ac1;
}
.companyMsg {
  text-align: left;
  margin-top: 50px;
}
#mapBox {
  height: 569px;
  margin: 50px 0;
}
@media screen and(max-width:1200px) {
  .bgimg {
    margin-top: 50px !important;
    height: 170px;
    img {
      object-fit: cover;
    }
  }

  .btnBox {
    margin: 25px 0 0 -100px;
  }
  .el-button {
    transform: scale(0.4);
    margin: 0 0px;
  }

  .companyMsg {
    h1 {
      text-align: center;
      font-size: 12px;
    }
    p {
      font-size: 12px;
    }
  }
  .companyProfile,
  .companyCulture {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 23px;
  }

  #mapBox {
    margin: 50px 0;
  }
}
</style>
